<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <form @submit.prevent="handleSubmitLocal">
            <div class="columns" id="etp">
              <div class="column">
                <div
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Email Template Properties</span>
                </div>
                <div class="block">
                  <div class="field is-grouped">
                    <div class="field">
                      <label class="label is-size-7">
                        Template Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.name" v-bind:readonly="notReportTemplate">
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Template Type
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="category" v-model="templateTypeCategory" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div
                  id="etclabel"
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Email Template Content</span>
                </div>
                <div class="block">
                  <div class="field is-grouped" id="etc">
                    <div class="field">
                      <label class="label is-size-7">Email Subject</label>
                      <div class="control">
                        <input
                          @click="changeFocus('subject')"
                          @onfocus="changeFocus('subject')"
                          @onselect="changeFocus('subject')"
                          class="input"
                          type="text"
                          name="name"
                          v-model="input.subject"
                          size="75"
                          ref="subject"
                        >
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">Email Personalization</label>
                      <div class="control">
                        <div class="select">
                          <select @change="personalizationChange" v-model="selectedToken">
                            <option selected disabled>Personalize the template</option>
                            <option v-for="token in tokens"
                                    :key="token.value"
                                    :value="token.value">
                              {{token.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field" v-if="maxHeight > 0">
                    <label
                      class="label is-size-7"
                      @click="changeFocus('body')"
                      @onfocus="changeFocus('body')"
                      @onselect="changeFocus('body')"
                      style=" top: 33px"
                    >Email Body</label>
                    <div class="control">
                      <tinymce
                        id="editor"
                        :init="{
                          height: maxHeight
                        }"
                        v-model="input.body"
                        ref="editor"
                        @onClick="changeFocus('body')"
                        plugins="code"
                        toolbar="code"
                        @click="changeFocus('body')"
                        @onfocus="changeFocus('body')"
                        @onselect="changeFocus('body')"
                        @editorChange="changeFocus('body')"
                        @input="changeFocus('body')"
                      ></tinymce>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="buttongroup">
              <div class="field is-grouped">
                <div class="control">
                  <button :disabled="!$hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 2)" type="submit" class="button is-accent has-text-white">Save</button>
                </div>
                <div class="control">
                  <a class="button is-light" @click="resetForm();$router.go(-1)">Cancel</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import Loading from './Loading'
let pageName = 'Email Template Details'

export default {
  components: {
    Loading
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          name: '',
          subject: '',
          body: '',
          type: 0
        }
      }
    }
  },
  data () {
    return {
      input: {
        name: '',
        subject: '',
        body: '',
        type: 0,
        emailTemplateTypeId: -1,
        emailTemplateTypeCategoryId: -1
      },
      focus: 'body',
      customToolbar: [['bold', 'italic', 'underline']],
      inputDefault: {},
      isLoading: false,
      templateTypes: [
        {
          id: 1,
          key: 'AccountAccess',
          name: 'Account Access'
        },
        {
          id: 2,
          key: 'Reporting',
          name: 'Reporting'
        }
      ],
      tokens: [],
      pageheading: pageName,
      notReportTemplate: true,
      selectedToken: 'Personalize the template',
      templateTypeCategory: '',
      templateTypeCategories: [],
      maxHeight: 0
    }
  },
  watch: {},
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      return !id || id <= 0
    }
  },
  mounted () {
    let that = this
    this.$nextTick(async () => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '#esep',
        '#buttongroup',
        '#etp',
        '#etc',
        '#etclabel',
        '#header-area',
        '#label'
      ]

      await this.setup()

      let func = async (results) => {
        let maxHeight = results[1]
        // this.take = numberOfResults + 2
        // this.perPage = numberOfResults + 2
        // this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight
      }

      that.getListHeights('body-content-area', 40, 300, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 300, elements, func.bind(that))
      })
    })
  },
  async beforeRouteEnter (to, from, next) {
    let template = null
    if (to.params.model) {
      template = JSON.parse(to.params.model)
    }
    if (template) {
      next(vm => {
        Object.assign(vm.input, template)
        vm.inputDefault = template
      })
    }
    next()
  },
  methods: {
    async setup () {
      // if external direct link, need to load the model state
      if (!this.isCreateMode && (!this.model || this.model.id <= 0)) {
        const template = (await this.loadModel('/email-templates/'))

        this.notReportTemplate = template.emailTemplateTypeId === 1
        this.templateTypeCategory = template.emailTemplateTypeCategory ? template.emailTemplateTypeCategory.description : template.type

        await this.getTokens(template.emailTemplateTypeId, template.emailTemplateTypeCategoryId)
      } else {
        this.setupForm(this.model)

        if (this.model) {
          let template = {}

          try {
            template = JSON.parse(this.model)
          } catch (e) {
            template = await this.loadModel('/email-templates/')
          }

          this.notReportTemplate = template.emailTemplateTypeId === 1
          this.templateTypeCategory = template.emailTemplateTypeCategory.description
          await this.getTokens(template.emailTemplateTypeId, template.emailTemplateTypeCategoryId)
        } else {
          await this.getTokens()
        }
      }
    },
    changeFocus (focus) {
      this.focus = focus
    },
    async handleSubmitLocal () {
      // this.input.body = this.input.body.replaceAll('<div><br></div>', '<br>').replaceAll('<p><br></p>') // hack to deal with quil https://github.com/quilljs/quill/issues/1074
      await this.handleSubmit(`/email-templates`, -1)
    },
    async getTokens (emailTemplateTypeId, emailTemplateTypeCategoryId) {
      try {
        let route = `/email-templates/tokens`
        if (emailTemplateTypeId && emailTemplateTypeCategoryId) {
          route = `/EmailTemplates/TemplateType/${emailTemplateTypeId}/TemplateTypeCategory/${emailTemplateTypeCategoryId}/tokens`
        }
        let response = await this.axios.get(route)
        this.tokens = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
      }
    },
    personalizationChange (event) {
      let token = event.target.value
      if (this.focus === 'subject') {
        let startPos = this.$refs.subject.selectionStart
        let endPos = this.$refs.subject.selectionEnd
        this.input.subject =
          this.input.subject.substring(0, startPos) +
          token +
          this.input.subject.substring(endPos, this.input.subject.length)
      }
      if (this.focus === 'body') {
        this.$refs.editor.editor.execCommand('mceInsertContent', false, token)
      }
    },
    async getEmailTemplateTypeCategories () {
      try {
        const route = `/EmailTemplates/TemplateTypeCategories`

        let response = await this.axios.get(route)
        this.templateTypeCategories = response.data
      } catch (error) {
        this.handleApiErr(error)
      }
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
input:-moz-read-only {
  background-color: #ccc;
}
input:read-only {
  background-color: #ccc;
}
</style>
